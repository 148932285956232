import axios from 'axios';

export default function callApi(method = 'post', url, data) {
  var axiosConfig = {
    method: method,
    url: url,
    data: data
  };
  return axios(axiosConfig);
}
