//import 'react-app-polyfill/ie11';
import React from 'react';
import { render, hydrate } from 'react-dom';
import { Router, Route } from 'react-router-dom';
import createBrowserHistory from './customHistory/createBrowserHistory';
//require('../node_modules/slick-carousel/slick/slick.scss');
import { loadableReady } from '@loadable/component';

//appDesktopTablet
import DesktopApp from './components/Containers/Desktop/DesktopApp/DesktopApp';
const initialState = window.__INITIAL_STATE__;

var historyObject = createBrowserHistory();

class wrapperComponent extends React.Component {
	render () {
		return <DesktopApp initialState={JSON.parse(JSON.stringify(initialState))} {...this.props} />;
	}
}

$(document).ready(function () {
	if ('scrollRestoration' in history) {
		// Back off, browser, I got this...
		history.scrollRestoration = 'manual';
	}
	window.addEventListener('beforeunload', function (event) {
		if ('scrollRestoration' in history) {
			// OK, browser, take it...
			history.scrollRestoration = 'auto';
		}
	});


	loadableReady(() => {
		hydrate(
			<Router history={historyObject}>
				<Route path='*' component={wrapperComponent} />
			</Router>,
			document.getElementById('root')
		);
		if (String(window.location).indexOf('local') == -1) window.__INITIAL_STATE__ = {};
	});
});
